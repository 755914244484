import React from 'react';
import { Link } from 'gatsby';
import Layout from 'src/components/layout/main';
import Section from 'src/components/base/section';
import Typography from "src/components/base/typography";
import { ButtonFlat } from 'src/components/base/button';
import HeroTitle from 'src/components/hero/title';
import Flex from 'src/components/base/flex';
import { ImageContain } from 'src/components/base/image';
import Icon from 'src/components/base/icon';
import LinkExternal from 'src/components/base/linkExternal';
import Capa from 'src/components/capabilities/capabilities';
// images
import thermal from "src/images/capabilities/thermal.png";
import dic from "src/images/home/dic.png";
import fea from "src/images/capabilities/fea.png";
import heatmap from "src/images/capabilities/heatmap.png";
import julialogo from "src/images/capabilities/julialogo.png";
import surepulse from "src/images/capabilities/surepulse.png";
import GithubIcon from 'src/images/icons/githubwhite.svg';

const CapabilitiesPage = (props) => {

  const renderArpaBlogPostLink = (hidden) => (
    <Link to="/blog/analysis-on-the-arpa-e-conformable-natural-gas-tank" data-hidden={hidden} data-hover="noline">
      <ButtonFlat>
        Read the blog post
      </ButtonFlat>
    </Link>
  )

  const IconLine = ({ icon, children }) => (
    <Flex.box justify="center" align="center">
      <Icon size="1em">{icon}</Icon>
      <span style={{ marginLeft: "1rem" }}>{children}</span>
    </Flex.box>
  )

  return (
    <Layout page="/capabilities">
      {/* Top Header */}
      <Section color="dark" bottom="4rem">
        <HeroTitle>Capabilities</HeroTitle>
      </Section>

      {/* Modeling and Simulation */}
      <Capa.item dark title="Modeling and Simulation" reverse>
        <Capa.content>
          <ImageContain src={thermal} alt="Thermal Imagery of Sauna" />
        </Capa.content>
        <Capa.content>
          <Capa.title>Modeling and Simulation</Capa.title>
          <p>
            Dynamic systems are often at the core of your problem. It is hard
            to know what effects changing part of the system can have. Laurium
            Labs can build a model for you, including the hard work of
            researching and estimating the dozens of constants that are
            involved in your problem.
          </p>
          <p>
            Ever wonder how the thermal dynamics of a sauna work? Laurium Labs
            built a software package that models the thermal dynamics of a
            sauna. Check it out at saunasim.com.
          </p>
        </Capa.content>
      </Capa.item>

      {/* Image Analysis */}
      <Capa.item>
        <Capa.content>
          <ImageContain src={dic} alt="DIC" />
        </Capa.content>
        <Capa.content>
          <Capa.title>Image Analysis</Capa.title>
          <p>
            Imagery has been cheaper to collect than ever, but how do you
            extract value from your imagery? Let Laurium Labs apply their
            knowledge of image science to your problem.
          </p>
          <p>
            Digital image correlation is the science of determining
            deformation and displacement from imagery. Laurium Labs produced a
            software package called DIC.jl

          </p>
          <Section top="2rem">
            <LinkExternal
              href="https://github.com/lauriumlabs/DIC.jl"
              style={{ display: "contents" }}
              data-hover="noline"
            >
              <ButtonFlat dark responsive>
                DIC.jl
              </ButtonFlat>
            </LinkExternal>
          </Section>
        </Capa.content>
      </Capa.item>

      {/* FEA and CFD */}
      <Capa.item dark reverse>
        <Capa.content>
          <ImageContain src={fea} alt="Natural gas tank simulation" />
          <Section y="2rem">
            <Typography weight="medium" size="6">
              ARPA-E conformable natural gas tank modeling and simulation
            </Typography>
          </Section>
          {renderArpaBlogPostLink("mobile tablet")}
        </Capa.content>
        <Capa.content>
          <Capa.title>FEA & CFD</Capa.title>
          <p>
            Doing FEA and CFD correctly takes a great deal of research into
            parameters, load cases, coefficients, and solvers. A "common-sense"
            check of the results is also necessary to sniff out what doesn’t
            make sense. Laurium Labs has years of experience with finite solvers
            to help you with your simulation needs.
          </p>
          <p className="pane-text grey-space-text">
            While working with REL (the technology has been spun off to Loukus
            Tech), Brent ran all internal analysis for a ARPA-E grant for a
            conformable natural gas tank that went onto several rounds of
            funding and met many of the technical goals of the project before a
            fall in gas prices lowered interest in natural gas as a fuel.
          </p>
          <Section top="1rem" data-hidden="desktop">
            {renderArpaBlogPostLink()}
          </Section>
        </Capa.content>
      </Capa.item>

      {/* Data Processing */}
      <Capa.item>
        <Capa.content>
          <Section y="1rem" data-hidden="mobile tablet">
            <Capa.title>Data Processing</Capa.title>
            <p>
              Measuring what happens in your system is very important.
              Processing that data into a useful format takes work. Laurium
              Labs can process your data, apply the correct mathematics, and
              display it to you with a beautiful UI.
            </p>
          </Section>
          <ImageContain src={surepulse} alt="Sure Pulse" />
        </Capa.content>
        <Capa.content>
          <Section y="1rem" data-hidden="desktop">
            <Capa.title>Data Processing</Capa.title>
            <p>
              Measuring what happens in your system is very important.
              Processing that data into a useful format takes work. Laurium
              Labs can process your data, apply the correct mathematics, and
              display it to you with a beautiful UI.
            </p>

          </Section>
          <Section y="1rem">
            <Section top="4rem" data-hidden="mobile tablet" />
            <Typography size="4" weight="bold" as="h4">
              Sure Pulse Open Source High Strain Rate Testing
            </Typography>
          </Section>
          <p>
            High strain rate events are the very violent events that happen
            around us. Unfortunately, materials behave differently at high
            strain rates than at the normal test equipment allows. These high
            strain rate tests must be run on specialized equipment called a
            Split Hopkinson Pressure Bar (SHPB).
          </p>
          <p>
            Custom software is necessary to process this data from raw voltage
            readings to stress strain plots. Laurium Labs helped conceive,
            develop and currently supports the Sure Pulse software with Leg
            Day Technology.
          </p>
          <Section top="2rem">
            <LinkExternal
              href="https://relinc.com/software"
              style={{ display: "contents" }}
              data-hover="noline"
            >
              <ButtonFlat dark responsive>
                relinc.com/software
              </ButtonFlat>
            </LinkExternal>
            <LinkExternal
              href="https://how-to-shpb.com"
              style={{ display: "contents" }}
              data-hover="noline"
            >
              <ButtonFlat dark responsive series>
                how-to-shpb.com
              </ButtonFlat>
            </LinkExternal>
          </Section>
        </Capa.content>
      </Capa.item>

      {/* Data Analysis */}
      <Capa.item dark reverse>
        <Capa.content>
          <ImageContain src={heatmap} alt="Heatmap" />
        </Capa.content>
        <Capa.content>
          <Capa.title>Data Analysis</Capa.title>
          <p>
            Ever have a large database that makes you curious about what
            hidden patterns lie within? Not sure how to start extracting that
            data? Laurium Labs can parse your data and do exploratory analysis
            to help you make the best use of your data.
          </p>
          <Typography as="h5" weight="bold" size="4">
            MessengerAnalyze.jl
          </Typography>
          <p>
            Laurium Labs has produced a software package called
            MessengerAnalyze.jl that parses the html format of Facebook’s
            archive to a Data Frame and analyzes Facebook Messenger
            conversations. To see this analysis applied to Brent's
            conversation with his wife from before dating to marriage, read
            here:
          </p>
          <Section top="1rem">
            <Link to="/blog/messaging-chloe" data-hover="noline">
              <ButtonFlat>Messaging Chloe</ButtonFlat>
            </Link>
          </Section>
        </Capa.content>
      </Capa.item>

      <Capa.item>
        <Capa.content>
          <ImageContain src={julialogo} alt="Julia Logo" />
        </Capa.content>
        <Capa.content>
          <Capa.title>Software Development</Capa.title>
          <p>
            Julia is an emerging language in technical and scientific
            development. Laurium Labs has three years of experience in Julia,
            and has strong relationships with other Julia developers. See
            below for examples of our work. You can rely on Laurium Labs for
            your Julia development needs.
          </p>
          <Section top="1rem">
            <LinkExternal href="https://saunasim.com" data-hover="noline">
              <ButtonFlat dark block>
                saunasim.com
              </ButtonFlat>
            </LinkExternal>
            <LinkExternal href="https://github.com/bhalonen/DIC.jl" data-hover="noline">
              <ButtonFlat dark block series>
                <IconLine icon={<GithubIcon />}>bhalonen/DIC.jl</IconLine>
              </ButtonFlat>
            </LinkExternal>
            <LinkExternal href="https://github.com/bhalonen/MessengerAnalyze.jl" data-hover="noline">
              <ButtonFlat dark block series>
                <IconLine icon={<GithubIcon />}>
                  bhalonen/MessengerAnalyze.jl
                </IconLine>
              </ButtonFlat>
            </LinkExternal>
          </Section>
        </Capa.content>
      </Capa.item>
    </Layout>
  )
}

export default CapabilitiesPage;