import styled from 'styled-components';

export const ImageContain = styled.img`
    width: 100%;
    max-width: ${props => props.width || '100%'};
    object-fit: contain;
    margin: 0 auto;
    display: block;
`

export default {
    contain: ImageContain,
}