import React from 'react';
import PropTypes from 'prop-types';
import Section from 'src/components/base/section';
import Container from "src/components/base/container";
import Typography from 'src/components/base/typography';
import Grid from "src/components/base/grid";
import useTheme from 'src/components/base/useTheme';

const fillHeight = {
  style: { height: '100%' }
}

const CapaTitle = ({children}) => (
  <Section bottom="1rem">
    <Typography as="h2" size="2" family="display" weight="bold" align="left" style={{ margin: 0 }}>
      {children}
    </Typography>
  </Section>
);

const CapaContent = ({ children }) => {
  const theme = useTheme();

  return (
    <Container small={theme.image.maxWidth + "px"} large="calc(100% - 4rem)" {...fillHeight}>
      {children}
    </Container>
  );
}

const CapaItem = ({ dark, children, reverse }) => {

    return (
      <Section color={dark ? 'silver' : 'white'} y="4rem">
        <Container>
          <Section x="1rem">
            <Grid.row reverse={reverse}>
              <Grid.column size="6">
                <Section y="1rem" {...fillHeight}>
                  {children[0] && children[0]}
                </Section>
              </Grid.column>
              <Grid.column size="6" {...fillHeight}>
                <Section y="1rem">{children[1] && children[1]}</Section>
              </Grid.column>
            </Grid.row>
          </Section>
        </Container>
      </Section>
    )
}

CapaItem.propTypes = {
    color: PropTypes.string,
    children: PropTypes.node,
    reverse: PropTypes.bool,
}

export { CapaItem, CapaTitle, CapaContent }

export default {
  item: CapaItem,
  title: CapaTitle,
  content: CapaContent,
}