import React from 'react';
import PropTypes from 'prop-types';
import Section from 'src/components/base/section';
import { H1 } from 'src/components/heading/heading';

const HeroTitle = ({ color, children }) => {
    return (
        <Section color={color} y="4rem">
            <H1>{children}</H1>
        </Section>
    );
}

HeroTitle.propTypes = {
    color: PropTypes.string,
    children: PropTypes.node,
}

export default HeroTitle;